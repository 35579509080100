export class AppMan {
  constructor() {
    this.apps = [];
  }

  register(selector, load) {
    this.apps.push({ selector, load });
  }

  async load() {
    await Promise.all(
      this.apps.map(async ({ selector, load }) => {
        while (window.document.querySelector(selector)) {
          // eslint-disable-next-line no-console
          // console.debug(`Loading app with selector: ${selector}`);
          await load(window.document.querySelector(selector));
          window.document.querySelector(selector).setAttribute('id', selector + '-used');
        }
      })
    );
  }
}
