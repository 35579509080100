import * as mixpanel from '@/libs/utils/mixpanel';
import $ from 'jquery';

$('.form-group').removeClass('row');

// UI-device
(function($) {
  'use strict';

  // Checks for ie
  if (!!navigator.userAgent.match(/MSIE/i) || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    $('body').addClass('ie');
  }

  // Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
  var ua = window['navigator']['userAgent'] || window['navigator']['vendor'] || window['opera'];
  if ((/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua)) {
    $('body').addClass('smart');
  }
})($);

// UI-nav
(function($) {

  $(document).on('click', '[ui-nav] a', function(e) {
    var $this = $(e.target);
    var $active;
    var $li;
    $this.is('a') || ($this = $this.closest('a'));

    $li = $this.parent();
    $active = $li.siblings('.active');
    $li.toggleClass('active');
    $active.removeClass('active');
  });
})($);

// UI-form
(function($) {
  'use strict';

  $('input, textarea').each(function() {
    $(this).val() ? $(this).addClass('has-value') : $(this).removeClass('has-value');
  });
  $(document).on('blur', 'input, textarea', function(e) {
    $(this).val() ? $(this).addClass('has-value') : $(this).removeClass('has-value');
  });
})($);

$('.disable-on-click').click(function() {
  $(this).prop('disabled', true);
});

mixpanel.register({ category: location.pathname });

mixpanel.track('page viewed', {
  'page name': document.title,
  'url': location.pathname,
  'org': org
});

$(document).ready(function() {
  $('#registerNewDeposit').on('click', () => {
    mixpanel.track('Click register new deposit');
  });
  $('.deposit-list .table tr').on('click', () => {
    mixpanel.track('Click view deposit');
  });
  $('.deposit-detail button#activate_deposit').on('click', () => {
    mixpanel.track('Click pay deposit');
  });
  $('.deposit-detail button#confirm_deposit_transfer').on('click', () => {
    mixpanel.track('Click confirm deposit payment');
  });
  $('.deposit-detail').on('click', '[data-mp="viewBatchPayment"], a#viewBatchPayment', () => {
    mixpanel.track('Click view batch payment');
  });
  $('.deposit-detail a#signDepositCertificate').on('click', () => {
    mixpanel.track('Click sign deposit');
  });
});
