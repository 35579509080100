import '@/../flatfair/base';
import '@/libs/utils/public_path';
import '@/libs/utils/hotjar';
import '@/libs/utils/sentry';
import '@/../scss/hellosign.scss';
import '@/../scss/app.scss';
import { AppMan } from './utils';
import * as Sentry from '@sentry/browser';

// eslint-disable-next-line no-console
// console.debug({ version: process.env.VERSION });

const man = new AppMan();

man.register('#active-challenge-app', () => import('@/apps/active_challenge_app'));
man.register('#active-tenant-changes-app', () => import('@/apps/active_tenant_changes_app'));
man.register('#ad-campaign-app', () => import('@/apps/ad_campaign_app'));
man.register('#batch-payments-detail-app', () => import('@/apps/batch_payments_detail_app'));
man.register('#create-batch-payments-app', () => import('@/apps/create_batch_payments_app'));
man.register('#create-claims-app', () => import('@/apps/create_claims_app'));
man.register('#deposits-sync-app', () => import('@/apps/deposits_sync_app'));
man.register('#draft-flatbonds-list-app', () => import('@/apps/draft_flatbonds_list_app'));
man.register('#fee-calculator-app', () => import('@/apps/fee_calculator_app'));
man.register('#flatbond-detail-status-app', () => import('@/apps/flatbond_detail_status_app'));
man.register('#flatbonds-to-action-app', () => import('@/apps/flatbonds_to_action_app'));
man.register('#invite-landlord-app', () => import('@/apps/invite_landlord_app'));
man.register('#invoicing-app', () => import('@/apps/invoicing_app'));
man.register('#onboarding-documents-app', () => import('@/apps/onboarding_documents_app'));
man.register('#ops-app', () => import('@/apps/ops_app'));
man.register('#partner-onboarding', () => import('@/apps/partner_onboarding_app'));
man.register('#partner-signup-app', () => import('@/apps/partner_signup_app'));
man.register('#payment-gateway-app', () => import('@/apps/payment_gateway_app'));
man.register('#payment-gateway-charges', () => import('@/apps/payment_gateway_charges'));
man.register('#referencing-app', () => import('@/apps/referencing_app'));
man.register('#referencing-tenant-app', () => import('@/apps/referencing_tenant_app'));
man.register('#create-deposit-app', () => import('@/apps/register_deposit_app'));
man.register('#rent-arrears-banner-app', () => import('@/apps/rent_arrears_banner_app'));
man.register('#review-detail-admin-app', () => import('@/apps/review_detail_admin_app'));
man.register('#review-plan-deposit-app', () => import('@/apps/review_plan_deposit_app'));
man.register('#settings-tabs-app', () => import('@/apps/settings_tabs_app'));

man.register('#charge-summary-app', element =>
  import('@/apps/charge_summary_app').then(app => {
    app.loadComponent(element);
  })
);

man.register('#review-list-app', () => import('@/apps/review_list_app'));

man.register('#sales-performance-app', () => import('@/apps/sales_performance_app'));
man.register('#sandbox-app', () => import('@/apps/sandbox_app'));
man.register('#section-box-app', () => import('@/apps/section_box_app'));
man.register('#select-branch-app', () => import('@/apps/select_branch_app'));

man.register('#tenant-confirm-adjudication-app', element =>
  import('@/apps/tenant_confirm_adjudication_app').then(app => {
    app.loadComponent(element);
  })
);

man.register('#tenant-eot-claims-app', () => import('@/apps/tenant_eot_claims_app'));
man.register('#tenant-referencing-app', () => import('@/apps/tenant_referencing_app'));
man.register('#trustpilot-review-banner-app', () => import('@/apps/trustpilot_review_banner_app'));
man.register('#flatbond-subscription-banner-app', () =>
  import('@/apps/flatbond_subscription_banner_app')
);
man.register('#flatbond-reports-app', () => import('@/apps/flatbond_reports_app'));

man.register('#plan-deadline-alert-app', () => import('@/apps/plan_deadline_alert_app'));
man.register('#tenant-public-payment-request-app', () =>
  import('@/apps/tenant_public_payment_request_app')
);
man.register('#flatbond-details-legacy-app', () => import('@/../flatfair/flatbond_detail'));

man.register('#nav-container-app', () => import('@/apps/nav_container_app'));

man.load().catch(err => {
  // eslint-disable-next-line no-console
  console.error(err);
  Sentry.captureException(err);
});
